$book-width: 190px;
$book-height: 280px;

.book {
  @include palette-background-paper;
  @include shade;
  border-radius: 24px;
  padding: $spacing1;
  margin: 0 $spacing2;
  transition: min-width 0.3s ease, transform 0.3s ease;
  @include breakpoints-up(sm) {
    display: flex;
    height: 310px;
  }
  figure {
    height: $book-height;
    border-radius: 24px;
    overflow: hidden;
    width: $book-width;
    @include breakpoints-down(xs) {
      height: 150px
    }
    img {
      display: block;
      height: 100%;
      @include breakpoints-up(sm) {
        width: $book-width;
      }
    }
  }
  figcaption {
    display: none;
    height: 45px;
    width: $book-height - 40px;
    color: #FFF;
    font-size: 24px;
    text-align: center;
    transform: rotate(-90deg) translate(20px, 7px);
    transform-origin: top left;
    border-radius: 24px;
    line-height: 45px;
    span {
      transform: scale(-1);
      display: block;
    }
  }
  &.expanded {
    flex: 1;
    position: relative;
    left: -10px;
    figure {
      @include breakpoints-down(xs) {
        margin-bottom: $spacing2;
        img {
          height: auto; width: 100%;
        }
      }
    }
    @include breakpoints-up(sm) {
      padding: $spacing2;
      min-width: 500px;
    }
  }

  &.collapsed {
    border-color: transparent !important;
    min-width: 75px;
    transform: rotate(-5deg);
    transform-origin: bottom left;
    cursor: pointer;
    figcaption {
      display: block;
    }
    &:hover {
      transform: rotate(0deg);
    }
    figure {
      width: auto;
      height: 296px;
    }
    .desc {
      overflow: hidden;
      width: 0;
      padding: 0;
      @include breakpoints-down(xs) {
        display: none;
      }
    }
  }

  .desc {
    padding: $spacing2;
    flex: 1;
    max-width: 320px;
    @include breakpoints-up(sm) {
      @include padding-right(0);
    }
    @include breakpoints-down(xs) {
      padding: 0
    }
    p {
      overflow: hidden;
      height: 120px;
      @include breakpoints-down(xs) {
        display: none;
      }
    }
    h6 {
      font-family: "Lato", sans-serif !important;
      font-weight: $font-bold
    }
    .button {
      width: 100%;
      padding: spacing(0.5, 3) !important
    }
  }

  .property {
    display: flex;
    justify-content: space-between;
    strong {
      font-weight: $font-bold;
      font-size: 18px;
    }
  }


  .text {
    @include margin-right($spacing2)
  }

  .rating {
    i {
      color: #FFC107
    }
  }

  .star-icon {
    color: #FFC107
  }

  .star-icon-disable {
    .theme--light & {
      color: $light-divider;
    }
    .theme--dark & {
      color: $dark-divider;
    }
  }

  .button {
    margin-top: $spacing2;
    line-height: 28px !important;
  }
}


