.error-style {
  .error-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: $spacing10;
    margin-top: $spacing10;
    @include breakpoints-down(sm) {
      margin: spacing(10, 0)
    }
  }
  .flex {
    display: flex;
    justify-content: center
  }
  .illustration {
    margin: $spacing5;
    position: relative;
    text-align: center;
    @include breakpoints-up(sm) {
      margin: spacing(2, 5);
    }
    img {
      position: relative;
      max-width: 350px;
      @include breakpoints-up(md) {
        @include right($spacing2);
      }
      @include breakpoints-down(xs) {
        max-width: 80%;
      }
    }
  }
  .text {
    @include breakpoints-up(md) {
      @include border-left(1px solid);
      @include palette-divider;
      @include padding-left($spacing5);
    }
    @include breakpoints-down(sm) {
      text-align: center;
      margin-top: $spacing10
    }
    h3 {
      font-size: 106px;
      text-transform: capitalize;
      font-weight: $font-bold;
    }
    h4 {
      margin-bottom: $spacing3  
    }
    p {
      @include palette-text-secondary
    }
  }
  .button {
    margin-top: $spacing2
  }
  
}
