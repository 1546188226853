.top-deco {
  border-radius: 60px;
  transform: rotate(45deg);
  position: absolute;
  @include left(20px);
  top: -30px;
  width: 300px;
  height: 300px;
  img {
    transform: rotate(-45deg);
    width: 400px;
    @include breakpoints-down(xs) {
      display: none;
    }
  }
  &:before {
    content: "";
    border-radius: 60px;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    @include use-theme(background-color, $palette-primary-light, $palette-primary-main);
    opacity: 0.3;
  }
  @include breakpoints-down(xs) {
    width: 200px;
    height: 200px;
  }
}