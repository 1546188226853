.counter-style {
  position: relative;
  padding-bottom: $spacing5;
  @include breakpoints-up(sm) {
    padding: spacing(10, 0);
  }
  &.dark{
    background: $palette-primary-main;
    .counter-item {
      color: $palette-common-white;
      .icon {
        color: $palette-common-white;
      }
    }
  }
  .counter-item {
    align-items: center;
    justify-content: center;
    @include breakpoints-up(md) {
      display: flex
    }
    @include breakpoints-down(sm) {
      text-align: center;
    }
    @include breakpoints-down(xs) {
      display: flex;
      @include text-align(left);
    }
    .icon {
      width: 40px;
      height: 40px;
      font-size: 80px;
      line-height: 80px;
      text-align: center;
      color: $palette-primary-main;
      @include breakpoints-up(md) {
        @include margin-right($spacing2);
        width: 80px;
        height: 80px;
      }
      @include breakpoints-down(xs) {
        @include margin-right($spacing2);
        width: 80px;
        height: 80px;
      }
    }
    .text {
      h4 {
        @include use-theme(color, $palette-primary-dark, $palette-primary-light);
        font-weight: bold;
      }
    }
  }
}
