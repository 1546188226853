#feature {
  .root {
    position: relative;
    width: 100%;
    @include breakpoints-up(sm) {
      padding-bottom: 20px;
    }
    @include breakpoints-between(sm, md) {
      padding-top: $spacing5
    }
    @include breakpoints-down(xs) {
      padding-bottom: 0
    }
  }

  .counter-item {
    align-items: center;
    display: flex;
    margin: spacing(2.5);
    @include breakpoints-down(xs) {
      justify-content: center;
    }
    @include breakpoints-down(xs) {
      margin: spacing(2, 4);
    }
    figure {
      @include margin-right($spacing5);
      border: 1px solid $palette-primary-main;
      border-radius: 10px;
      transform: rotate(45deg);
      width: 100px;
      height: 100px;
      line-height: 100px;
      text-align: center;
      vertical-align: middle;
      @include breakpoints-down(xs) {
        width: 70px;
        height: 70px;
        line-height: 70px;
      }
      img {
        transform: translateY(10px) rotate(-45deg);
        width: 110%;
        max-height: 120px;
        margin: 0 auto;
      }
    }
    .text {
      @include breakpoints-down(xs) {
        flex: 1
      }
    }
    h4 {
      font-size: 36px;
      @include breakpoints-down(sm) {
        margin-top: $spacing3
      }
      @include breakpoints-down(xs) {
        font-size: 24px;
      }
    }
    h6 {
      @include palette-text-secondary
    }
  }
}