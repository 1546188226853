.blog-card {
  display: flex;
  margin-bottom: $spacing6;
  align-items: center;
  position: relative;
  @include breakpoints-down(xs) {
    margin-bottom: $spacing3;
  }
  &:before {
    border-radius: 15px;
    content: '';
    width: 100px;
    height: 100px;
    transform: rotate(45deg);
    background: $palette-secondary-main;
    opacity: 0.1;
    position: absolute;
    top: -10px;
    @include left(-25px);
  }
  .text {
    a {
      padding: 0;
      position: relative;
      text-transform: none;
      z-index: 1;
    }
    > a {
      font-weight: $font-regular;
      @include palette-text-secondary;
    }
    h4 {
      line-height: normal;
      &:before {
        border-radius: 10px;
        content: '';
        width: 50px;
        height: 50px;
        transform: rotate(45deg);
        @include use-theme(background, $palette-secondary-light, $palette-secondary-dark);
        position: absolute;
        top: -6px;
        @include left(-25px);
      }
      a {
        display: block;
        font-size: 22px;
        font-weight: $font-regular;
        transition: all 0.3s ease;
        height: 80px;
        @include breakpoints-down(xs) {
          font-size: 16px;
          line-height: 22px;
        }
        &:hover {
          color: $palette-primary-main
        }
      }
    }
  }
  .date {
    text-align: center;
    @include margin-left($spacing3);
    @include palette-text-hint;
    & > * {
      display: block;
      line-height: normal;
    }
    h3 {
      font-size: 28px;
      text-transform: uppercase;
      @include breakpoints-down(xs) {
        font-size: 20px
      }
    }
    h2 {
      font-size: 46px;
      @include breakpoints-down(xs) {
        font-size: 32px
      }
    }
    h4 {
      font-size: 22px;
      @include breakpoints-down(xs) {
        font-size: 16px
      }
    }
  }
}