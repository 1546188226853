.about-style {
  .zero {
    .progress {
      .progress-linear {
        width: 0 !important;
      }
    }
  }

  .showimg {
    position: absolute;
    width: 382px;
    z-index: 6;
    max-width: calc(100% - 24px);
    top: 33px;
    left: 12px;
    border-radius: 12px;
    overflow: hidden;
    height: 250px;
    @include breakpoints-up('lg') {
      height: 340px;
    }
    a {
      width: 100%;
      height: 100%;
      display: block;
      img {
        width: 100%;
        opacity: 0;
      }
    }
  }

  .inner {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .text {
    h4 {
      font-weight: $font-bold;
      margin-bottom: $spacing2;
    }
    h5 {
      @include palette-text-secondary
    }
  }
  
  .video-root {
    @include breakpoints-down(sm) {
      text-align: center;
    }
  }

  .video-wrap {
    @include breakpoints-down(sm) {
      max-width: 400px;
      margin: 0 auto;
    }
  }

  .video-popup {
    max-width: none;
    h4 {
      text-overflow: ellipsis;
      white-space: nowrap;
      flex: 1;
      overflow: hidden;
    }
    @include breakpoints-up(sm) {
      width: 690px;
    }
    iframe {
      width: 100%
    }
  }

  .close-btn {
    position: absolute;
    top: 4px;
    @include right(4px);
  }

  .progress-wrap {
    @include breakpoints-up(sm) { 
      padding: spacing(5, 0);
    }
    ul {
      margin: 0;
      padding: 0;
      li {
        list-style: none;
        margin-bottom: $spacing3;
        &:last-child {
          margin-bottom: 0
        }
        h5 {
          font-weight: $font-bold
        }
      }
    }
  }

  .text-icon {
    margin-bottom: $spacing1;
    display: flex;
    align-items: center;
    i {
      font-size: 28px;
      padding-top: 8px;
    }
    h5 {
      @include margin-left($spacing1)
    }
  }

  .progress {
    border-radius: 10px;
    height: 10px;
    @include use-theme(background, $light-divider, $dark-divider);
    .progress-linear {
      border-radius: 10px;
      &.primary {
        background: $palette-primary-main
      }
      &.secondary {
        background: $palette-secondary-main
      }
      &.accent {
        background: $palette-accent-main
      }
    }
  }

  .bg-wrapper {
    @include use-theme(background-color, $palette-secondary-light, $palette-secondary-dark);
    padding: spacing(5, 0);
    position: relative;
    @include breakpoints-down(sm) {
      border-radius: 40px 40px 0 0;
      overflow: hidden;
    }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      @include use-theme(background-color, $palette-common-white, $palette-common-black);
      opacity: 0.8;
    }
    & > div {
      position: relative;
    }
  }

  .gallery-root, .team-root {
    @include breakpoints-down(sm) {
      text-align: center
    }
    .slick-dots {
      bottom: $spacing3 * -1
    }
  }

  .team-root {
    .item > *{
      @include breakpoints-down(xs) {
        width: 280px;
      }
    }
  }

  .carousel {
    position: relative;
    .icon {
      font-size: 32px;
      @include rtl-flip;
    }
    .nav {
      @include breakpoints-down(xs) {
        display: none;
      }
    }
  }
  
  .item {
    position: relative
  }

  .nav {
    position: absolute;
    top: 50%;
    font-size: 38px;
  }

  .prev {
    @include left(-30px);
  }

  .next {
    @include right(-30px);
  }
}